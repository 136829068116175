import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Page,
  Icon,
  FormLayout,
  Card,
  TextStyle,
  Modal,
  Stack,
  Subheading,
  Toast,
  Banner,
} from "@shopify/polaris";
import {
  ProfileMajor,
  ListMajor,
  LocationMajor,
  SearchMajor,
  JobsMajor,
} from "@shopify/polaris-icons";
import Cookies from "js-cookie";
import axios from "../Assets/Libraries/axios";
import { useParams } from "react-router-dom";
import History from "./History";
import { CircleTickMajor, CircleAlertMajor } from "@shopify/polaris-icons";
import moment from "moment";

function LeadDetails(props) {
  const { id } = useParams();
  const [regulatorsValue, setRegulatorsValue] = useState("");
  const [responseDetails, setResponseDetails] = useState([]);
  const [responseProfile, setResponseProfile] = useState([]);
  const [emptyForm, setEmptyForm] = useState(true);
  const [countries, setCountries] = useState([]);
  const format = "YYYY-MM-DD HH:mm:ss";
  var dateFormat = require("dateformat");
  const [popupActive, setPopupActive] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Account Recreated" onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    const ac = new AbortController();
    setPopupActive(true);

    Promise.all([
      axios.get(`admin/v1/currentdesk/countries`, { signal: ac.signal }),
      axios.get(`admin/v1/leads/${id}/kyc-form`, { signal: ac.signal }),
    ]).then(function (responses) {
      setPopupActive(false);
      const responseCountries = responses[0].data.data;
      const responseKycForm = responses[1].data;
      if (
        responseKycForm.data &&
        responseKycForm.data.form &&
        responseKycForm.data.form.hasOwnProperty("residential_address") === true
      ) {
        setResponseDetails(responseKycForm.data.form);
        setRegulatorsValue(responseKycForm.data.form.regulators);
        setEmptyForm(false);
      }
      responseKycForm.profile && setResponseProfile(responseKycForm.profile);

      let countriesArray = [];
      for (var i = 0; i < responseCountries.length; i++) {
        countriesArray.push({
          id: responseCountries[i].Id,
          value: responseCountries[i].Value,
        });
      }
      setCountries(countriesArray);
    });
    return () => {
      ac.abort();
    };
  }, []);
  const handleRecreateAccount = () => {
    setPopupActive(true);
    axios
      .get(`admin/v1/leads/${id}/recreate-account`)
      .then((res) => {
        setPopupActive(false);
        toggleActive();
      })
      .catch((err) => console.log(err));
  };
  const switchParam = (param) => {
    switch (param) {
      case "leads":
        return "leads";
      case "promofix":
        return "promofix-leads";
      case "africa":
        return "africa-leads";
      case "users":
        return "users";

      default:
      // code block
    }
  };
  return (
    <Page
      title="Details"
      breadcrumbs={[
        {
          content: "List of leads",
          url: `/${switchParam(props.type)}`,
        },
      ]}
      primaryAction={{
        content: "Recreate Account",
        onClick: handleRecreateAccount,
        disabled:
          (emptyForm && String(responseProfile.access_type) === "0") ||
          (responseDetails?.profile?.other &&
            responseDetails?.profile?.other["CD_status"] === 200 &&
            true),
      }}
    >
      <Card sectioned>
        <FormLayout>
          <Card
            sectioned
            title="PROFILE"
            actions={[
              {
                content: "Edit",

                url: `/${switchParam(props.type)}/${id}/editprofile`,
              },
            ]}
          >
            <FormLayout>
              {responseProfile.first_name && (
                <div>
                  <TextStyle variation="strong">First Name: </TextStyle>
                  <TextStyle>{responseProfile.first_name}</TextStyle>
                </div>
              )}

              {responseProfile.last_name && (
                <div>
                  <TextStyle variation="strong">Last Name: </TextStyle>
                  <TextStyle>{responseProfile.last_name}</TextStyle>
                </div>
              )}

              {responseProfile.title_id != null && (
                <div>
                  <TextStyle variation="strong">Title: </TextStyle>
                  <TextStyle>{switchTitle(responseProfile.title_id)}</TextStyle>
                </div>
              )}

              {responseProfile.date_of_birth && (
                <div>
                  <TextStyle variation="strong">Date of birth: </TextStyle>
                  <TextStyle>{responseProfile.date_of_birth}</TextStyle>
                </div>
              )}

              {responseProfile?.email && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <TextStyle variation="strong">Email: </TextStyle>
                  <TextStyle>{responseProfile.email}</TextStyle>
                  {responseProfile?.email_verified_at ? (
                    <Icon source={CircleTickMajor} color="primary"></Icon>
                  ) : (
                    <Icon source={CircleAlertMajor} color="critical"></Icon>
                  )}
                </div>
              )}

              {responseProfile?.created_at && (
                <div>
                  <TextStyle variation="strong">Created At: </TextStyle>
                  {/* <Moment date={responseProfile.created_at} /> */}
                  <TextStyle>
                    {dateFormat(
                      responseProfile.created_at,
                      "yyyy-mm-dd h:MM:ss"
                    )}
                  </TextStyle>
                </div>
              )}

              {responseProfile?.personal_mobile_number && (
                <div>
                  <TextStyle variation="strong">Phone Number: </TextStyle>
                  <TextStyle>
                    {responseProfile.personal_mobile_number}
                  </TextStyle>
                </div>
              )}
              {responseProfile?.regulator && (
                <div>
                  <TextStyle variation="strong">Regulator: </TextStyle>
                  <TextStyle>{responseProfile.regulator}</TextStyle>
                </div>
              )}
              {responseProfile?.trading_type &&
                String(responseProfile.access_type) === "1" && (
                  <div>
                    <TextStyle variation="strong">Trading Type: </TextStyle>
                    <TextStyle>{responseProfile?.trading_type}</TextStyle>
                  </div>
                )}
            </FormLayout>
          </Card>
          {responseProfile?.utm && responseProfile?.utm?.length ? (
            <Card sectioned title="UTMs">
              <ul>
                {Object.keys(responseProfile.utm).map(function (
                  keyName,
                  keyIndex
                ) {
                  return (
                    <li key={keyName}>
                      {`${keyName}: ${responseProfile.utm[keyName]}`}
                    </li>
                  );
                })}
              </ul>
            </Card>
          ) : null}

          <History regulators={regulatorsValue} countries={countries} />
          {responseProfile?.email_verified_at && (
            <Banner status="success">
              <b>Email verified on:</b>{" "}
              {moment(responseProfile.email_verified_at).format(format)}
            </Banner>
          )}
          {emptyForm ? (
            <Card sectioned title="KYC FORM">
              <TextStyle>No form uploaded yet</TextStyle>
            </Card>
          ) : (
            <Card
              title="KYC FORM"
              actions={[
                {
                  content: "Edit",
                  url: `/${switchParam(props.type)}/${id}/editkycform`,

                  disabled: Cookies.get("4t-adminrole") === "editor",
                },
              ]}
            >
              <Card.Section
                title={
                  <Stack>
                    <Icon source={LocationMajor} />
                    <Subheading>RESIDENTIAL ADDRESS</Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  <FormLayout.Group>
                    {responseDetails.residential_address.nationality && (
                      <FormLayout>
                        <TextStyle variation="strong">Nationality: </TextStyle>
                        <TextStyle>
                          {findArrayElementById(
                            countries,
                            responseDetails.residential_address.nationality
                          ) &&
                            findArrayElementById(
                              countries,
                              responseDetails.residential_address.nationality
                            ).value.en}
                        </TextStyle>
                      </FormLayout>
                    )}
                    {responseDetails.residential_address.nationality && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Country Of Residence:
                        </TextStyle>
                        <TextStyle>
                          {findArrayElementById(
                            countries,
                            responseDetails.residential_address
                              .country_of_residence
                          ) &&
                            findArrayElementById(
                              countries,
                              responseDetails.residential_address
                                .country_of_residence
                            ).value.en}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {responseDetails.residential_address.city && (
                      <FormLayout>
                        <TextStyle variation="strong">City: </TextStyle>
                        <TextStyle>
                          {responseDetails.residential_address.city}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.residential_address.address && (
                      <FormLayout>
                        <TextStyle variation="strong">Address: </TextStyle>
                        <TextStyle>
                          {responseDetails.residential_address.address}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {/* <FormLayout>
                    <TextStyle variation="strong">
                      Time at the current address (years) :
                    </TextStyle>
                    <TextStyle>
                      {
                        responseDetails.residential_address
                          .time_at_the_current_address
                      }
                    </TextStyle>
                  </FormLayout> */}

                    {responseDetails.residential_address.passport_number && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Passport Number:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.residential_address.passport_number}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.residential_address.us_citizen && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          US person (US citizen):
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.residential_address.us_citizen}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {/* <FormLayout>
                    <TextStyle variation="strong">Passport Photo :</TextStyle>
                    <Button
                      onClick={() =>
                        openFile(
                          process.env.REACT_APP_BASE_URL +
                            "/" +
                            responseDetails.residential_address.passport_photo
                        )
                      }
                    >
                      View File
                    </Button>
                  </FormLayout> */}
                    {responseDetails.residential_address.passport_photo && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Passport Photo:
                        </TextStyle>
                        <Button
                          onClick={() =>
                            openFile(
                              "https://4tprod.s3.eu-central-1.amazonaws.com/" +
                                responseDetails.residential_address
                                  .passport_photo
                            )
                          }
                        >
                          View File
                        </Button>
                      </FormLayout>
                    )}
                    {responseDetails.residential_address.proof_of_address && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Proof of address:
                        </TextStyle>
                        <Button
                          onClick={() =>
                            openFile(
                              "https://4tprod.s3.eu-central-1.amazonaws.com/" +
                                responseDetails.residential_address
                                  .proof_of_address
                            )
                          }
                        >
                          View File
                        </Button>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {responseDetails.residential_address
                      .identification_type && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Identification Type:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.regulators === "lebanon"
                            ? getIdentificationTypeLebanon(
                                responseDetails.residential_address
                                  .identification_type
                              )
                            : getIdentificationTypeSeychelles(
                                responseDetails.residential_address
                                  .identification_type
                              )}
                        </TextStyle>
                      </FormLayout>
                    )}
                    {responseDetails.residential_address
                      .identification_number && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Identification Number:
                        </TextStyle>
                        <TextStyle>
                          {
                            responseDetails.residential_address
                              .identification_number
                          }
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  {/* <FormLayout.Group>
                  <FormLayout>
                    <TextStyle variation="strong">Proof of address :</TextStyle>
                    <Button
                      onClick={() =>
                        openFile(
                          process.env.REACT_APP_BASE_URL +
                            "/" +
                            responseDetails.residential_address.proof_of_address
                        )
                      }
                    >
                      View File
                    </Button>
                  </FormLayout>
                </FormLayout.Group> */}
                </FormLayout>
              </Card.Section>

              <Card.Section
                title={
                  <Stack>
                    <Icon source={SearchMajor} />
                    <Subheading>EMPLOYMENT AND FINANCIAL BACKGROUND</Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  <FormLayout.Group>
                    {responseDetails.financial_background.employment_status && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Employment Status:
                        </TextStyle>
                        <TextStyle>
                          {getEmploymentStatus(
                            responseDetails.financial_background
                              .employment_status
                          )}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.financial_background.position_title && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Occupation / Position Title:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.financial_background.position_title}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {responseDetails.financial_background.employer_name && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Employer’s Name:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.financial_background.employer_name}
                        </TextStyle>
                      </FormLayout>
                    )}
                    {responseDetails.financial_background.employer_address && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Employer’s Address:
                        </TextStyle>
                        <TextStyle>
                          {
                            responseDetails.financial_background
                              .employer_address
                          }
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {responseDetails.financial_background
                      .employer_telephone && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Employer’s telephone:
                        </TextStyle>
                        <TextStyle>
                          {
                            responseDetails.financial_background
                              .employer_telephone
                          }
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.financial_background.annual_income && (
                      <FormLayout>
                        <TextStyle variation="strong">Annual Income:</TextStyle>
                        <TextStyle>
                          {responseDetails.regulators === "lebanon"
                            ? getAnnualIncomeLebanon(
                                responseDetails.financial_background
                                  .annual_income
                              )
                            : getAnnualIncomeSeychelles(
                                responseDetails.financial_background
                                  .annual_income
                              )}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>
                  {responseDetails.financial_background.company_name &&
                    responseDetails.regulators === "seychelles" && (
                      <FormLayout.Group>
                        <FormLayout>
                          <TextStyle variation="strong">
                            Company Name:
                          </TextStyle>
                          <TextStyle>
                            {responseDetails.financial_background.company_name}
                          </TextStyle>
                        </FormLayout>
                      </FormLayout.Group>
                    )}
                  <FormLayout.Group></FormLayout.Group>
                  <FormLayout.Group>
                    {responseDetails.financial_background.net_worth && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Approximate net worth (excluding primary residence):
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.regulators === "lebanon"
                            ? getNetWorthLebanon(
                                responseDetails.financial_background.net_worth
                              )
                            : getNetWorthSeychelles(
                                responseDetails.financial_background.net_worth
                              )}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.financial_background.source_of_wealth && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Source(s) of Wealth:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.financial_background
                            .other_source_of_wealth
                            ? responseDetails.financial_background
                                .other_source_of_wealth
                            : getSourceOfWealth(
                                responseDetails.financial_background
                                  .source_of_wealth
                              )}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {responseDetails.financial_background
                      .estimate_deposite_size && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Estimate deposit size during a 12 months period:
                        </TextStyle>
                        <TextStyle>
                          {
                            responseDetails.financial_background
                              .estimate_deposite_size
                          }
                        </TextStyle>
                      </FormLayout>
                    )}
                    {responseDetails.financial_background
                      .previous_position_title && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Previous position title:
                        </TextStyle>
                        <TextStyle>
                          {
                            responseDetails.financial_background
                              .previous_position_title
                          }
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section
                title={
                  <Stack>
                    <Icon source={JobsMajor} />
                    <Subheading>TRADING EXPERIENCE</Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  <FormLayout.Group>
                    {responseDetails.trading_experience.id && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Trading Experience:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.regulators === "lebanon"
                            ? getTardingExperienceIDlebanon(
                                String(responseDetails.trading_experience.id)
                              )
                            : getTardingExperienceIDseychelles(
                                String(responseDetails.trading_experience.id)
                              )}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.trading_experience
                      .how_often_do_you_expext_to_trade && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          How often do you expect to trade:
                        </TextStyle>
                        <TextStyle>
                          {
                            responseDetails.trading_experience
                              .how_often_do_you_expext_to_trade
                          }
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {responseDetails.trading_experience.futures && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Futures and Options:
                        </TextStyle>
                        <TextStyle>
                          {switchFuturesAndOptions(
                            responseDetails.trading_experience.futures
                          )}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.trading_experience
                      .cfd_foreign_exchange && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          CFDs or Foreign Exchange Derivatives:
                        </TextStyle>
                        <TextStyle>
                          {switchDerivatives(
                            responseDetails.trading_experience
                              .cfd_foreign_exchange
                          )}
                        </TextStyle>
                      </FormLayout>
                    )}
                  </FormLayout.Group>

                  <FormLayout.Group>
                    {responseDetails.trading_experience.account_purpose && (
                      <FormLayout>
                        <TextStyle variation="strong">
                          Main purpose of the account:
                        </TextStyle>
                        <TextStyle>
                          {responseDetails.trading_experience
                            .other_account_purpose
                            ? responseDetails.trading_experience
                                .other_account_purpose
                            : getMainPurposeOfTheAccount(
                                responseDetails.trading_experience
                                  .account_purpose
                              )}
                        </TextStyle>
                      </FormLayout>
                    )}

                    {responseDetails.trading_experience.currency &&
                      String(responseProfile.access_type) !== "1" && (
                        <FormLayout>
                          <TextStyle variation="strong">Currency:</TextStyle>
                          <TextStyle>
                            {switchCurrency(
                              responseDetails.trading_experience.currency
                            )}
                          </TextStyle>
                        </FormLayout>
                      )}
                  </FormLayout.Group>
                  <FormLayout.Group>
                    {responseDetails.trading_experience.type &&
                      String(responseProfile.access_type) !== "1" && (
                        <FormLayout>
                          <TextStyle variation="strong">
                            Metatrader Platform:
                          </TextStyle>
                          <TextStyle>
                            {responseDetails.trading_experience.type}
                          </TextStyle>
                        </FormLayout>
                      )}
                  </FormLayout.Group>
                </FormLayout>
              </Card.Section>
              <Card.Section
                title={
                  <Stack>
                    <Icon source={JobsMajor} />
                    <Subheading>
                      PROFESSIONAL EXPERIENCE AND ACADEMIC KNOWLEDGE
                    </Subheading>
                  </Stack>
                }
              >
                <FormLayout>
                  {responseDetails.professional_experience
                    .relevant_experience && (
                    <div>
                      <TextStyle variation="strong">
                        Do you have relevant professional experience?
                      </TextStyle>
                      <TextStyle>
                        {
                          responseDetails.professional_experience
                            .relevant_experience
                        }
                      </TextStyle>
                    </div>
                  )}
                  {responseDetails.professional_experience.academic && (
                    <div>
                      <TextStyle variation="strong">
                        Do you have any relevant professional or academic?
                      </TextStyle>
                      <TextStyle>
                        {responseDetails.professional_experience.academic}
                      </TextStyle>
                    </div>
                  )}

                  {responseDetails.professional_experience.beneficiary && (
                    <div>
                      <TextStyle variation="strong">
                        Are you the beneficiary owner of the account?
                      </TextStyle>
                      <TextStyle>
                        {responseDetails.professional_experience.beneficiary}
                      </TextStyle>
                    </div>
                  )}

                  {responseDetails.professional_experience.director && (
                    <div>
                      <TextStyle variation="strong">
                        Are you a director or officer of a publicly listed
                        company?{" "}
                      </TextStyle>
                      <TextStyle>
                        {responseDetails.professional_experience.director}
                      </TextStyle>
                    </div>
                  )}

                  {responseDetails.professional_experience.qualifications && (
                    <div>
                      <TextStyle variation="strong">Qualifications:</TextStyle>
                      <TextStyle>
                        {responseDetails.professional_experience.qualifications}
                      </TextStyle>
                    </div>
                  )}
                </FormLayout>
              </Card.Section>
              {responseDetails.politically_exposed_person && (
                <Card.Section>
                  <TextStyle variation="strong">
                    Are you a politically exposed person?
                  </TextStyle>{" "}
                  <TextStyle>
                    {responseDetails.politically_exposed_person}
                  </TextStyle>
                </Card.Section>
              )}
            </Card>
          )}
        </FormLayout>
      </Card>
      <Modal open={popupActive} loading={true}></Modal>
      {toastMarkup}
    </Page>
  );
  function openFile(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  function switchFuturesAndOptions(param) {
    switch (String(param)) {
      case "never":
        return "Never";
      case "1 to 15 trades":
        return "1 to 15 trades";
      case "15 to 40 trades":
        return "15 to 40 trades";
      case "More than 40 trades":
        return "More than 40 trades";

      default:
        return "";
    }
  }
  function switchDerivatives(param) {
    switch (String(param)) {
      case "never":
        return "Never";
      case "1 to 15 trades":
        return "1 to 15 trades";
      case "15 to 40 trades":
        return "15 to 40 trades";
      case "More than 40 trades":
        return "More than 40 trades";

      default:
        return "";
    }
  }
  function switchCurrency(param) {
    switch (String(param)) {
      case "5":
        return "USD";
      case "3":
        return "EU";

      default:
        return "";
    }
  }
  // function switchFuturesAndOptions(param) {
  //   switch (String(param)) {
  //     case "never":
  //       return "Never";
  //     case "1 to 15 occasions":
  //       return "1 to 15 occasions";
  //     case "15 to 40 occasions":
  //       return "15 to 40 occasions";
  //     case "More than 40 occasions":
  //       return "More than 40 occasions";

  //     default:
  //       return "";
  //   }
  // }
  function switchTitle(param) {
    switch (String(param)) {
      case "1":
        return "Mr.";
      case "2":
        return "Mrs.";
      case "4":
        return "Ms.";
      case "5":
        return "Dr.";
      case "6":
        return "Prof.";
      default:
        return "";
    }
  }
  function getTardingExperienceIDlebanon(param) {
    switch (param) {
      case "30":
        return "None";
      case "31":
        return "1 - 3 Years";
      case "32":
        return "3 - 5 Years";
      case "33":
        return "5+ Years";
      default:
        return "";
    }
  }
  function getTardingExperienceIDseychelles(param) {
    switch (param) {
      case "34":
        return "None";
      case "35":
        return "1 - 3 Years";
      case "36":
        return "3 - 5 Years";
      case "37":
        return "5+ Years";
      default:
        return "";
    }
  }
  function getIdentificationTypeLebanon(param) {
    switch (param) {
      case "203":
        return "Passport";
      case "204":
        return "Drivers License";
      case "205":
        return "Government ID";

      default:
        return "";
    }
  }
  function getIdentificationTypeSeychelles(param) {
    switch (param) {
      case "212":
        return "Passport";
      case "213":
        return "Drivers License";
      case "214":
        return "Government ID";

      default:
        return "";
    }
  }
  function getNetWorthLebanon(param) {
    switch (param) {
      case "323":
        return "Less than $25,000";
      case "324":
        return "$25,000-$100,000";
      case "325":
        return "$100,001-$500,000";
      case "326":
        return "Over $500,000";
      default:
        return "";
    }
  }
  function getNetWorthSeychelles(param) {
    switch (param) {
      case "335":
        return "Less than $25,000";
      case "336":
        return "$25,000-$100,000";
      case "337":
        return "$100,001-$500,000";
      case "338":
        return "$Over $500,000";
      default:
        return "";
    }
  }
  function getAnnualIncomeLebanon(param) {
    switch (param) {
      case "305":
        return "Less than $15,000";
      case "306":
        return "$15,000-$50,000";
      case "307":
        return "$50,001-$100,000";
      case "308":
        return "Over $100,000";
      default:
        return "";
    }
  }
  //
  function getAnnualIncomeSeychelles(param) {
    switch (param) {
      case "317":
        return "Less than $15,000";
      case "318":
        return "$15,000-$50,000";
      case "319":
        return "$50,001-$100,000";
      case "320":
        return "Over $100,000";
      default:
        return "";
    }
  }
  function getSourceOfWealth(param) {
    switch (param) {
      case "1":
        return "Employment";
      case "2":
        return "Investment";
      case "3":
        return "Inheritance";
      case "4":
        return "Other";
      case "28":
        return "Pension";
      default:
        return "";
    }
  }
  function getMainPurposeOfTheAccount(param) {
    switch (param) {
      case "3":
        return "Capital Growth'";
      case "4":
        return "Speculation";
      case "5":
        return "Income";
      case "6":
        return "Hedging";
      case "7":
        return "Other";
      default:
        return "";
    }
  }
  function getEmploymentStatus(param) {
    switch (param) {
      case "1":
        return "Employed";
      case "2":
        return "Unemployed";
      case "3":
        return "Self-Employed";
      case "4":
        return "Retired";
      case "5":
        return "Student";
      default:
        return "";
    }
  }
  function findArrayElementById(array, id) {
    return array.find((element, index) => {
      return parseInt(element.id) === parseInt(id);
    });
  }
}

export default LeadDetails;
